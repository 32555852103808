/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'ins': {
    width: 130,
    height: 130,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M512 64C264.6 64 64 264.6 64 511.9 64 759.4 264.6 960 512 960s448-200.6 448-448.1C960 264.6 759.4 64 512 64zm0 95.9c24.2 0 44 19.8 44 44 0 24.3-19.8 44-44 44s-44-19.7-44-44c0-24.2 19.8-44 44-44zm-264.6 443c-1.8 11.6-8.1 21.8-17.6 28.6-7.8 5.7-16.8 8.4-25.8 8.4-13.6 0-27-6.2-35.7-18.1-7-9.5-9.8-21.1-7.8-32.8 1.8-11.6 8.1-21.8 17.5-28.6 9.5-7 21.1-9.8 32.8-7.9 11.6 1.8 21.8 8.1 28.7 17.6 7.1 9.5 9.8 21.2 7.9 32.8zm-43.4-131c-24.3 0-44-19.7-44-43.9 0-24.3 19.7-44.1 44-44.1 24.2 0 44 19.8 44 44 0 24.3-19.8 44-44 44zM314.9 808c-24.2 0-44-19.8-44-44.1 0-24.2 19.8-44 44-44s44 19.8 44 44c0 24.3-19.8 44.1-44 44.1zm44.5-541c-1.9 11.6-8.2 21.8-17.7 28.6-7.5 5.4-16.5 8.4-25.7 8.4-2.3 0-4.6-.2-7-.6-11.7-1.8-21.8-8.1-28.7-17.6-7-9.5-9.8-21.1-7.9-32.7 1.8-11.6 8.1-21.8 17.6-28.7 9.5-7 21.2-9.8 32.8-7.9 11.6 1.8 21.8 8.1 28.7 17.6 7 9.6 9.8 21.2 7.9 32.9zm153.2 316.9l-74.1 102c-5.8 7.9-15.1 11.9-25.5 11.9-9.7 0-20.3-3.4-29.9-10.4-11.3-8.2-18.9-19.5-20.7-31-1.3-8.2.5-16.1 5-22.2l75.2-103.5c-1.7-6.2-2.5-12.4-2.5-18.7 0-39.7 32.3-72 72-72s72 32.3 72 72.1c-.1 39.3-32.1 71.6-71.5 71.8zM703.8 808c-24.2 0-44-19.8-44-44.1 0-24.2 19.8-44 44-44s44 19.8 44 44c0 24.3-19.7 44.1-44 44.1zm30-512.5c-7.6 5.5-16.6 8.4-25.8 8.4-2.3 0-4.6-.2-7-.6-11.7-1.8-21.8-8.1-28.7-17.6-7-9.5-9.7-21.2-7.8-32.8 1.8-11.6 8.1-21.8 17.6-28.6s21.1-9.7 32.7-7.8c11.6 1.8 21.8 8.1 28.7 17.6 14.3 19.5 10 47.1-9.7 61.4zm86.2 88.4c24.2 0 44 19.8 44 44.1 0 24.2-19.8 43.9-44 43.9s-44-19.7-44-43.9c0-24.3 19.8-44.1 44-44.1zm25.9 247.7c-7.7 5.5-16.6 8.4-25.8 8.4-2.4 0-4.7-.2-7-.6-11.6-1.8-21.8-8.1-28.7-17.6-6.9-9.4-9.7-21-7.9-32.6s8.1-21.8 17.6-28.8l.1-.1c19.6-14.2 47.2-9.8 61.4 9.8 14.2 19.7 9.9 47.2-9.7 61.5z" _fill="#979797"/>'
  }
})
