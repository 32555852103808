import Vue from 'vue';
import { Message } from 'element-ui';

const offset = 100
//定义一个新的Message方法，多传入一个offset参数
const $message: any = (options: any) => {
  return Message({
    ...options,
    offset,
  });
};

//重写一遍success的方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = (message: string) => {
    const options: any = {
      message: message,
      offset,
      type,
    };
    return Message(options);
  };
});
//将$message挂载到this上
function rewriteMessage() {
  Vue.prototype.$message = $message;
}
export {
  rewriteMessage
}
