






import { Component, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
import watermark from "watermark-dom";
// @ts-ignore
import { UserModule } from "@/store/modules/user";

@Component({
  name: "App",
})
export default class extends Vue {
  get user() {
    return UserModule.user;
  }
  @Watch("user")
  handleWatchUserModule(value: any) {
    if (value?.name && value?.telephone) {
      watermark.load({
        watermark_txt: `${UserModule.user.name}${UserModule.user.telephone}`,
        watermark_alpha: 0.05,
        watermark_fontsize: "14px",
      });
    } else {
      watermark.remove();
    }
  }
}
