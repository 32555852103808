import Vue from 'vue'
import { UserModule } from '@/store/modules/user'

Vue.directive('permission', {
  inserted: (el, binding) => {
    const permissionCode = binding.value
    if (!permissionCode) return
    const { permission, isSuperAdmin, user }: any = UserModule
    // 当前企业admin
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id)
    if (isAdmin) return
    // 超管不校验页面权限
    if (isSuperAdmin) return
    // 非超管需要校验页面权限
    if (!(permission as any).includes(permissionCode)) {
      (el as any)?.parentNode?.removeChild(el)
    }
  }
})

Vue.directive('permissions', {
  inserted: (el, binding) => {
    const permissionCodes = binding.value
    if (!permissionCodes?.length) return
    const { permission, isSuperAdmin, user }: any = UserModule
    // 当前企业admin
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id)
    if (isAdmin) return
    // 超管不校验页面权限
    if (isSuperAdmin) return
    // 非超管需要校验页面权限
    if (!permissionCodes?.filter((codes: string) => permission.includes(codes))?.length) {
      (el as any)?.parentNode?.removeChild(el)
    }
  }
})
