function getQueryParamByKey(paramName: any) {
  let url = document.location.toString();
  // 如果url中有特殊字符则需要进行一下解码
  url = decodeURI(url);
  const arrObj = url.split('?');
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&');
    let arr;
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=');
      if (arr !== null && arr[0] === paramName) {
        return decodeURIComponent(arr[1]);
      }
    }
    return '';
  }
  return '';
}

function addOrUpdateQuery(key: string, value: any, type = "pushState") {
  let url = type === "location" ? location.href : location.hash;

  if (!url.includes("?")) {
    url = `${url}?${key}=${value}`;
  } else {
    if (!url.includes(key)) {
      url = `${url}&${key}=${value}`;
    } else {
      const re = `(\\?|&|\#)${key}([^&|^#]*)(&|$|#)`;
      url = url.replace(new RegExp(re), "$1" + key + "=" + value + "$3");
    }
  }
  if (type === "location") {
    location.href = url;
  }

  if (type === "pushState") {
    history.pushState({}, "", url);
  }
}

export {
  getQueryParamByKey,
  addOrUpdateQuery,
}
