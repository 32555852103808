/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'role': {
    width: 228.516,
    height: 200,
    viewBox: '0 0 1170 1024',
    data: '<path pid="0" d="M146.286 0H1024a146.286 146.286 0 01146.286 146.286v731.428A146.286 146.286 0 011024 1024H146.286A146.286 146.286 0 010 877.714V146.286A146.286 146.286 0 01146.286 0zm219.428 585.143a146.286 146.286 0 100-292.572 146.286 146.286 0 000 292.572zM158.72 731.429h413.989a219.502 219.502 0 00-413.989 0zm572.709-365.715v73.143H1024v-73.143H731.429zm0 219.429v73.143H1024v-73.143H731.429z" _fill="#979797"/>'
  }
})
