import Vue from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import '@/permission'
import '@/utils/directives'
import { rewriteMessage } from '@/utils/message'
import VueRouter from 'vue-router'
// @ts-ignore
import SlideVerify from "vue-monoplasty-slide-verify";
import SearchBar from "@/components/SearchBar/index.vue";


const originalPush = VueRouter.prototype.push
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
VueRouter.prototype.push = function push(location) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore 调用回调方法 让浏览器不再报出这个错误
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(ElementUI, { size: 'mini' })
Vue.use(SlideVerify)
Vue.component('search-bar', SearchBar)
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

Vue.config.productionTip = false
rewriteMessage()
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
