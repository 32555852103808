import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout/index.vue";
import PERMISSION from "@/constants/permission";

Vue.use(Router);

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

export default new Router({
  // mode: 'history',  // Enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      component: () =>
        import(/* webpackChunkName: "login" */ "@/views/login/index.vue"),
      meta: { hidden: true, title: "登录" },
    },
    {
      path: "/password",
      redirect: "",
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/login/ChangePassword.vue"
        ),
      meta: { hidden: true, title: "修改密码" },
    },
    {
      path: "/404",
      component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
      meta: { hidden: true, title: "404" },
    },
    {
      name: "dashboard",
      path: "/",
      component: Layout,
      redirect: "/dashboard",
      children: [
        {
          path: "/dashboard",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "@/views/dashboard/index.vue"
            ),
          meta: {
            title: "首页",
            icon: "dashboard",
          },
        },
      ],
    },
    {
      name: "user",
      path: "/user",
      component: Layout,
      meta: {
        title: "用户",
        icon: "user",
        permissionCodes: [PERMISSION.USER, PERMISSION.ROLE],
      },
      redirect: "/user/user",
      children: [
        {
          path: "/user/user",
          component: () =>
            import(/* webpackChunkName: "menu1" */ "@/views/user/index.vue"),
          meta: { title: "用户", permissionCode: PERMISSION.USER },
        },
        {
          path: "/user/role",
          component: () =>
            import(/* webpackChunkName: "menu2" */ "@/views/role/index.vue"),
          meta: { title: "角色", permissionCode: PERMISSION.ROLE },
        },
      ],
    },
    {
      name: "department",
      path: "/department",
      component: Layout,
      meta: {
        permissionCodes: [PERMISSION.DEPARTMENT],
      },
      children: [
        {
          path: "department",
          component: () =>
            import(
              /* webpackChunkName: "form" */ "@/views/department/index.vue"
            ),
          meta: {
            title: "科室",
            icon: "depart",
            permissionCode: PERMISSION.DEPARTMENT,
          },
        },
      ],
    },
    {
      name: "instrument",
      path: "/ins",
      component: Layout,
      meta: {
        title: "仪器管理",
        icon: "ins",
        permissionCodes: [
          PERMISSION.INSTRUMENT,
          PERMISSION.ORDER,
          PERMISSION.INSTRUMENT_GROUP,
        ],
      },
      redirect: "/ins/ins",
      children: [
        {
          path: "/ins/ins",
          component: () =>
            import(
              /* webpackChunkName: "menu1" */ "@/views/instrument/index.vue"
            ),
          meta: { title: "仪器", permissionCode: PERMISSION.INSTRUMENT },
        },
        {
          name: "ins-detail",
          path: "/ins/detail/:id",
          component: () =>
            import(
              /* webpackChunkName: "menu1" */ "@/views/instrument/detail.vue"
            ),
          meta: {
            title: "仪器详情",
            hidden: true,
            lastBreadcrumb: {
              path: "/ins/ins",
              meta: {
                title: "仪器",
              },
            },
          },
        },
        {
          path: "/ins/order",
          component: () =>
            import(
              /* webpackChunkName: "menu1" */ "@/views/instrument/order.vue"
            ),
          meta: {
            title: "工单",
            permissionCode: PERMISSION.ORDER,
          },
        },
        {
          path: "/ins/group",
          component: () =>
            import(
              /* webpackChunkName: "menu2" */ "@/views/instrument/group.vue"
            ),
          meta: { title: "分组", permissionCode: PERMISSION.INSTRUMENT_GROUP },
        },
      ],
    },
    {
      name: "myEnterprise",
      path: "/my-enterprise",
      component: Layout,
      meta: {
        userOnly: true,
        permissionCodes: [PERMISSION.ENTERPRISE],
      },
      children: [
        {
          name: "myEnterpriseDetail",
          path: "/my-enterprise-detail",
          component: () =>
            import(
              /* webpackChunkName: "form" */ "@/views/enterprise/EnterpriseDetail.vue"
            ),
          meta: {
            title: "我的企业",
            icon: "enter",
            permissionCode: PERMISSION.ENTERPRISE,
          },
        },
      ],
    },
    {
      name: "enterprise",
      path: "/enterprise",
      component: Layout,
      meta: {
        superAdminOnly: true,
        permissionCodes: [PERMISSION.ENTERPRISE],
      },
      children: [
        {
          path: "/enterprise-list",
          name: "enter",
          component: () =>
            import(
              /* webpackChunkName: "form" */ "@/views/enterprise/Enterprises.vue"
            ),
          meta: {
            title: "企业",
            icon: "enter",
            superAdminOnly: true,
            permissionCode: PERMISSION.ENTERPRISE,
          },
        },
        {
          name: "enterpriseDetail",
          path: "/enterprise/:enterpriseName",
          component: () =>
            import(
              /* webpackChunkName: "form" */ "@/views/enterprise/EnterpriseDetail.vue"
            ),
          meta: {
            hidden: true,
            title: "企业详情",
            superAdminOnly: true,
            permissionCode: PERMISSION.ENTERPRISE,
            lastBreadcrumb: {
              path: "/enterprise-list",
              meta: {
                title: "企业",
              },
            },
          },
        },
      ],
    },
    {
      path: "/system",
      component: Layout,
      meta: {
        title: "系统管理",
        icon: "system",
        permissionCodes: [
          PERMISSION.SYSTEM_MANAGE_CHECK,
          PERMISSION.SYSTEM_MANAGE_FIX,
          PERMISSION.SYSTEM_MANAGE_ADJUST,
          PERMISSION.SYSTEM_MANAGE_SCRAP,
          PERMISSION.SYSTEM_MANAGE_VERITY,
          PERMISSION.FILE,
          PERMISSION.ORDER_CREATE,
        ],
      },
      redirect: "/check",
      children: [
        {
          path: "/approve",
          component: () =>
            import(
              /* webpackChunkName: "form" */ "@/views/instrument/orders/index.vue"
            ),
          redirect: "/check",
          meta: {
            title: "审批管理",
            icon: "example",
            permissionCodes: [
              PERMISSION.SYSTEM_MANAGE_CHECK,
              PERMISSION.SYSTEM_MANAGE_FIX,
              PERMISSION.SYSTEM_MANAGE_ADJUST,
              PERMISSION.SYSTEM_MANAGE_SCRAP,
              PERMISSION.SYSTEM_MANAGE_VERITY,
            ],
          },
          children: [
            {
              path: "/create",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/instrument/orders/create.vue"
                  ),
              meta: {
                title: "新增",
                permissionCode: PERMISSION.SYSTEM_MANAGE_VERITY,
              },
            },
            {
              path: "/check",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/instrument/orders/check.vue"
                ),
              meta: {
                title: "验收",
                permissionCode: PERMISSION.SYSTEM_MANAGE_CHECK,
              },
            },
            {
              path: "/adjust",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/instrument/orders/adjust.vue"
                ),
              meta: {
                title: "调整",
                permissionCode: PERMISSION.SYSTEM_MANAGE_ADJUST,
              },
            },
            {
              path: "/fix",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/instrument/orders/fix.vue"
                ),
              meta: {
                title: "维修",
                permissionCode: PERMISSION.SYSTEM_MANAGE_FIX,
              },
            },

            {
              path: "/scrap",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/instrument/orders/scrap.vue"
                ),
              meta: {
                title: "处置",
                permissionCode: PERMISSION.SYSTEM_MANAGE_SCRAP,
              },
            },
            {
              path: "/verity",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/instrument/orders/verity.vue"
                ),
              meta: {
                title: "检定校准",
                permissionCode: PERMISSION.SYSTEM_MANAGE_VERITY,
              },
            },
            {
              path: "/rangecheck",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/instrument/orders/rangecheck.vue"
                ),
              meta: {
                title: "期间核查",
                permissionCode: PERMISSION.SYSTEM_MANAGE_VERITY,
              },
            },
            {
              path: "/process/full",
              component: () =>
                import(
                  /* webpackChunkName: "form" */ "@/views/process/list.vue"
                ),
              meta: {
                superAdminOnly: true,
                title: "流程管理-完整",
              },
            },
            {
              path: "/process/process-detail",
              component: () =>
                import(
                  /* webpackChunkName: "publish-detail" */ "@/views/process/components/update-process.vue"
                ),
              name: "process-detail",
              meta: {
                title: "流程详情",
                hidden: true,
                superAdminOnly: true,
                lastBreadcrumb: {
                  path: "/process/full",
                  meta: {
                    title: "流程管理-完整",
                  },
                },
              },
            },
          ],
        },
        {
          path: "/order",
          component: () =>
            import(
              /* webpackChunkName: "form" */ "@/views/process/list-simple.vue"
            ),
          meta: {
            title: "审批发起",
            icon: "tree",
            permissionCodes: [PERMISSION.ORDER_CREATE],
          },
        },
        {
          path: "/file",
          component: () =>
            import(/* webpackChunkName: "form" */ "@/views/files/index.vue"),
          meta: {
            title: "文件管理",
            icon: "file",
            permissionCodes: [PERMISSION.FILE],
          },
        },
      ],
    },
    {
      path: "/wxjumper",
      component: () => import(/* webpackChunkName: "form" */ "@/views/wx.vue"),
      meta: {
        title: "微信跳转",
        hidden: true,
      },
    },
    {
      path: "*",
      redirect: "/404",
      meta: { hidden: true, title: "404" },
    },
    {
      name: "openInstrument",
      path: "/ins/open",
      component: Layout,
      meta: {
        title: "仪器列表",
        icon: "ins",
      },
      redirect: "/ins/open",
      children: [
        {
          path: "/ins/open",
          component: () =>
            import(
              /* webpackChunkName: "menu1" */ "@/views/instrument/open_ins_list.vue"
              ),
          meta: { title: "仪器列表", icon: "ins" },
        }
      ],
    },
  ],
});
