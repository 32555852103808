import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'

NProgress.configure({ showSpinner: false })

router.beforeEach(async (to: Route, _: Route, next: any) => {
  if (to.path == "/wxjumper") {
    next()
    return
  }
  // Start progress bar
  NProgress.start()

  if (!UserModule?.user?.name) {
    if (to.path === '/login' || to.path === '/password') {
      next()
      NProgress.done()
    } else {
      try {
        await UserModule.GetUserInfo()
        // 超管或者企业管理员无需校验权限
        // @ts-ignore
        const isAdmin = UserModule?.user?.manage_enterprise_ids?.includes(UserModule?.user?.enterprise_id);
        if (UserModule.isSuperAdmin || (isAdmin && !to.meta?.superAdminOnly)) {
          next({ ...to, replace: true })
          // 如果有权限或者不需要权限管控放行
        } else if ((!to.meta?.permissionCode || (UserModule?.permission as any)?.includes(to.meta?.permissionCode)) && !to.meta?.superAdminOnly) {
          next({ ...to, replace: true })
        } else {
          next('/404')
        }
        NProgress.done()
      } catch (e) {
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  } else {
    // 超管无需校验权限
    // @ts-ignore
    const isAdmin = UserModule?.user?.manage_enterprise_ids?.includes(UserModule?.user?.enterprise_id);
    if (UserModule.isSuperAdmin || (isAdmin && !to.meta?.superAdminOnly)) {
      next()
      // 如果有权限或者不需要权限管控放行
    } else if ((!to.meta?.permissionCode || (UserModule?.permission as any)?.includes(to.meta?.permissionCode)) && !to.meta?.superAdminOnly) {
      next()
    } else {
      next('/404')
    }
    NProgress.done()
  }
})

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done()

  // set page title
  document.title = `IIMS - ${to.meta.title}`
})

router.onError((error) => {
  // 路由异步加载出现error重新加载一次页面
  const pattern = /ChunkLoadError: Loading chunk/g;
  const isChunkLoadFailed = error.message.match(pattern);
  // const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    // router.replace(targetPath);
    window.location.reload();
  }
})

