import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import { ILoginParams, IUserDetail, IUserParams, userApi } from '@/api/user'
import { instrumentApi } from "@/api/instrument";
import { getToken, removeToken } from '@/utils/cookies'
import store from '@/store'
import PERMISSION from '@/constants/permission'

export interface IUserState {
  user: IUserDetail
  isSuperAdmin: boolean
  permission: Array<string>
  config?: any
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public user = {} as IUserDetail
  public isSuperAdmin = false
  public permission = []
  public config = {}

  @Mutation
  private SET_USER(user: IUserDetail) {
    this.user = user
  }
  @Mutation
  private SET_CONFIG(config: any) {
    this.config = config
  }

  @Mutation
  private SET_SUPER_ADMIN(superAdmin: boolean) {
    this.isSuperAdmin = superAdmin
  }

  @Mutation
  private SET_PERMISSION(userInfo: IUserDetail) {
    const key = PERMISSION.PERMISSION_KEY
    const permission = (userInfo as any).perms?.find((c: any) => Object.keys(c)?.[0] === key)?.[key]?.reduce((s: any, c: any) => {
      const [a] = c.split('-')
      if (s.includes(`${key}-${a}`)) {
        return [...s, `${key}-${c}`]
      }
      return [...s, `${key}-${a}`, `${key}-${c}`]
    }, [])
    this.permission = permission
  }

  @Action({ rawError: true })
  public async Login(params: ILoginParams) {
    const userInfo = await userApi.login(params)
    const config = await instrumentApi.getConfig();
    try {
      this.SET_CONFIG(config)
      this.SET_USER(userInfo)
      this.SET_SUPER_ADMIN(userInfo.isSuperAdmin === 1)
      this.SET_PERMISSION(userInfo)
    } catch (e) {
      //
    } finally {
      //
    }
  }

  @Action
  public async GetUserInfo() {
    const userInfo = await userApi.currentUser()
    const config = await instrumentApi.getConfig();
    this.SET_CONFIG(config)
    this.SET_USER(userInfo)
    this.SET_SUPER_ADMIN(userInfo.isSuperAdmin === 1)
    this.SET_PERMISSION(userInfo)
  }

  @Action
  public ResetUser() {
    this.SET_USER({} as IUserDetail)
    removeToken()
  }

  @Action
  public async LogOut() {
    await userApi.logout()
    this.SET_USER({} as IUserDetail)
    removeToken()
  }
}

export const UserModule = getModule(User)
