/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'system': {
    width: 130,
    height: 130,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M1017.378 456.01c-3.99-22.373-25.432-44.9-47.618-49.978l-16.45-3.803c-39.016-11.78-73.666-37.86-95.667-75.942-22.186-38.356-27.236-81.92-17.634-121.807l4.86-15.611c6.67-21.72-1.994-51.634-19.256-66.345 0 0-15.643-13.21-59.71-38.7-44.06-25.458-63.191-32.41-63.191-32.41-21.378-7.727-51.543-.371-66.997 16.33l-11.594 12.463c-29.666 28.045-69.645 45.216-113.585 45.216-44.152 0-84.258-17.295-113.954-45.527l-11.188-12.092C370 51.134 339.803 43.747 318.427 51.478c0 0-19.132 6.916-63.29 32.405-43.997 25.491-59.547 38.64-59.547 38.64-17.326 14.71-26.02 44.5-19.29 66.313l4.829 15.769c9.475 39.854 4.364 83.326-17.634 121.654-22.063 38.236-57.056 64.38-96.228 76.03l-16.08 3.678c-22.09 5.05-43.565 27.609-47.554 49.95 0 0-3.613 20.1-3.613 71.11 0 51.013 3.613 71.02 3.613 71.02 4.02 22.435 25.462 44.933 47.553 50.105l15.71 3.492c39.325 11.714 74.41 37.953 96.6 76.344 22.09 38.328 27.14 81.892 17.572 121.78l-4.767 15.58c-6.73 21.687 1.963 51.6 19.35 66.309 0 0 15.55 13.213 59.644 38.702 44.093 25.552 63.165 32.411 63.165 32.411 21.408 7.727 51.509.312 66.967-16.27l11.062-11.901c29.697-28.356 69.83-45.618 114.047-45.618 44.19 0 84.388 17.387 114.21 45.618h.028l10.94 11.902c15.454 16.58 45.493 23.996 66.93 16.27 0 0 19.072-6.98 63.322-32.412 44.002-25.49 59.58-38.702 59.58-38.702 17.328-14.708 25.928-44.497 19.323-66.31l-4.861-16.08c-9.35-39.761-4.24-83.14 17.634-121.279 22.25-38.453 57.277-64.57 96.538-76.344v-.065l15.706-3.612c22.187-5.051 43.629-27.546 47.615-49.984 0 0 3.614-20.068 3.614-71.11-.124-50.794-3.737-70.862-3.737-70.862zm-506.87 235.582c-90.589 0-164.068-73.665-164.068-164.532 0-90.742 73.478-164.343 164.068-164.343 90.584 0 163.97 73.633 163.97 164.468 0 90.867-73.386 164.407-163.97 164.407z" _fill="#979797"/>'
  }
})
