import http, { encodeParams } from '@/utils/request'
import { IRoleDetail } from './role'
import { IEnterprise } from "@/api/enterprise";

export interface IDepartmentDetail {
  departmentId: number
  departmentName: string
}

export interface IEnterpriseDetail {
  enterpriseId: number
  enterpriseName: string
}

export interface IUserDetail {
  departments: Array<IDepartmentDetail>
  enterprises: Array<IEnterpriseDetail>
  gmtCreate: string
  gmtModify: string
  id: number
  isSuperAdmin: number
  name: string
  openId: string
  password: string
  status: number
  telephone: string
  roles: Array<IRoleDetail>
}

export interface ICurrentUser extends IUserDetail {
  enterprise: IEnterprise
}

export interface IUsers {
  total: number
  page: number
  perPage: number
  items: Array<IUserDetail>
}

export interface IUserParams {
  name: string
  enterpriseId: number
  departmentId: number
  status: number
  roleId: number
  page: number
  perPage: number
  baseOnly: number
}

export interface ICreateUserParams {
  name: string
  status: number
  roleIds: Array<number>
  telephone: string
  enterpriseId: Array<number>
  departmentIds: Array<number>
}

export interface IModifyUserParams {
  id: number
  name: string
  status: number
  roleIds: Array<number>
  telephone: string
  enterpriseId: Array<number>
  departmentIds: Array<number>
}

export interface ILoginParams {
  telephone: string
  password: string
}

export interface IChangePasswordParams {
  telephone: string
  oldPassword: string
  newPassword: string
}

export const userApi = {
  login: function (params: ILoginParams): Promise<ICurrentUser> {
    return http.post('/user/login', params)
  },
  logout: function () {
    return http.post('/user/logout')
  },
  changePassword: function (params: IChangePasswordParams) {
    return http.put('/user/password', params)
  },
  currentUser: function (): Promise<ICurrentUser> {
    return http.get('/user/me')
  },
  getUsers: function (params: IUserParams): Promise<IUsers> {
    return http.get('/user' + encodeParams(params))
  },
  createUser: function (param: ICreateUserParams): Promise<{ id: number }> {
    return http.post('/user', param)
  },
  modifyUser: function (param: IModifyUserParams): Promise<{ id: number }> {
    return http.put(`/user/${param.id}`, param)
  },
  deleteUser: function (userId: number): Promise<{ id: number }> {
    return http.delete(`/user/${userId}`)
  },
  deleteUserRoles: function (userId: number): Promise<{ id: number }> {
    return http.delete(`/user/role/${userId}`)
  },
  resetUserPassword: function (user_id: number): Promise<{ id: number }> {
    return http.post(`/user/reset/${user_id}`)
  },
  sendWxLoginQrcode: function (qrcode: string) {
    return http.post(`/user/login/wx/qrcode/${qrcode}`)
  },
  getWxLoginQrcodeResult: function (qrcode: string): Promise<{ login: boolean }> {
    return http.get(`/user/login/wx/qrcode/${qrcode}`)
  },
  getDIngdingLoginResult: function (code: string): Promise<{ login: boolean }> {
    return http.post(`/user/login/dingding/${code}`)
  },
}
