

































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getQueryParamByKey, addOrUpdateQuery } from "@/utils";
// @ts-ignore
import * as _ from "lodash";
import { encodeParams } from "@/api/base";

@Component
export default class SearchBar extends Vue {
  @Prop({ default: "" }) queryKey!: string;
  @Prop({ default: "condition" }) uuid!: string;
  @Prop({ default: "" }) queryPlaceholder!: string;
  @Prop({ default: false }) loading?: boolean;
  @Prop({ default: () => [] }) extraConfig?: any[];
  @Prop({ default: true }) needCache?: boolean;
  @Prop({ default: true }) showLeft?: boolean;
  @Prop({ default: 300 }) inputWidth?: number;
  condition: any = {};
  defaultCondition: any = {};

  @Watch("condition", { deep: true })
  handleCondition(v: any) {
    if (this.needCache) {
      const params = encodeURIComponent(JSON.stringify(v));
      addOrUpdateQuery(this.uuid, params);
    }
  }

  get inputStyle() {
    return `width: ${this.inputWidth}px`;
  }

  get _queryKey() {
    return this.queryKey;
  }

  get _queryPlaceholder() {
    return this.queryPlaceholder;
  }

  get lodashRefresh() {
    return _.debounce(this.handleRrfresh, 250);
  }

  get avalibleExtraConfig() {
    return (
      this.extraConfig?.filter(
        (child) => !child.hidden && child.type !== "eitherOr"
      ) || []
    );
  }

  get avalibleEitherOrConfig() {
    return (
      this.extraConfig?.filter(
        (child) => !child.hidden && child.type === "eitherOr"
      ) || []
    );
  }

  created() {
    const params: any = {};
    this.extraConfig?.forEach((child: any) => {
      params[child.key] = null;
    });
    this.defaultCondition = JSON.parse(JSON.stringify(params));
    const initCondition = getQueryParamByKey(this.uuid);
    if (initCondition) {
      this.condition = JSON.parse(decodeURIComponent(initCondition));
    } else {
      this.condition = JSON.parse(JSON.stringify(params));
    }
    this.lodashRefresh();
  }

  filterMethod(v: any) {
    // console.log(v,'filterMethod')
  }

  handleRrfresh() {
    const params = JSON.parse(JSON.stringify(this.condition));
    if (this.avalibleEitherOrConfig?.length) {
      this.avalibleEitherOrConfig.forEach((child) => {
        if (this.condition[child.key]) {
          params[child.key] = child.value;
        } else {
          params[child.key] = null;
        }
      });
    }
    Object.keys(params).forEach((key) => {
      if (params[key] === null) {
        delete params[key];
      }
    });
    this.$emit("refresh", params);
  }

  reset() {
    this.condition = JSON.parse(JSON.stringify(this.defaultCondition));
    this.lodashRefresh();
  }
}
