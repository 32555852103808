export default {
  // 页面相关权限对应的key是5
  PERMISSION_KEY: '5',
  /** 用户 */
  // 用户路由
  USER: '5-7',
  // 用户录入
  USER_ADD: '5-7-21',
  // 用户编辑
  USER_EDIT: '5-7-22',
  // 用户删除
  USER_DELETE: '5-7-23',
  // 用户锁定
  USER_LOCK: '5-7-24',
  // 用户导入
  USER_IMPORT: '5-7-25',
  // 用户导出
  USER_EXPORT: '5-7-26',
  // 用户重置密码
  USER_RESET_PASSWORD: '5-7-27',
  USER_RESET_READONLY: '5-7-28',

  /** 角色 */
  // 角色路由
  ROLE: '5-8',
  // 角色删除
  ROLE_DELETE: '5-8-31',
  // 角色修改
  ROLE_EDIT: '5-8-30',
  // 角色新增
  ROLE_ADD: '5-8-29',
  ROLE_READONLY: '5-8-32',

  /** 科室 */
  // 科室路由
  DEPARTMENT: '5-2',
  // 科室新增
  DEPARTMENT_ADD: '5-2-3',
  // 科室修改
  DEPARTMENT_EDIT: '5-2-4',
  // 科室删除
  DEPARTMENT_DELETE: '5-2-5',
  // 科室只读
  DEPARTMENT_READONLY: '5-2-6',

  /** 企业 */
  // 企业路由
  ENTERPRISE: '5-1',
  // 企业只读
  ENTERPRISE_READONLY: '5-1-2',
  // 企业新增
  ENTERPRISE_ADD: '',
  // 企业编辑
  ENTERPRISE_EDIT: '5-1-1',

  /** 仪器 */
  // 仪器路由
  INSTRUMENT: '5-3',
  // 仪器导入
  INSTRUMENT_IMPORT: '5-3-12',
  // 仪器导出
  INSTRUMENT_EXPORT: '5-3-11',
  // 仪器只读
  INSTRUMENT_READONLY: '5-3-10',
  // 仪器新增
  INSTRUMENT_ADD: '5-3-7',
  // 仪器修改
  INSTRUMENT_EDIT: '5-3-8',
  // 仪器删除
  INSTRUMENT_DELETE: '5-3-9',
  // 仪器删除
  INSTRUMENT_ELE: '5-3-45',

  /** 仪器组 */
  // 仪器组路由
  INSTRUMENT_GROUP: '5-4',
  // 仪器组删除
  INSTRUMENT_GROUP_DELETE: '5-4-14',
  // 仪器组新增
  INSTRUMENT_GROUP_ADD: '5-4-13',
  // 仪器组只读
  INSTRUMENT_GROUP_READONLY: '5-4-15',

  /** 操作记录 */
  // 操作记录路由
  OPERATING_LOG: '5-6',
  // 操作记录修改
  OPERATING_LOG_EDIT: '5-6-18',
  // 操作记录删除
  OPERATING_LOG_DELETE: '5-6-19',
  OPERATING_LOG_READONLY: '5-6-20',

  /** 流程 */
  // 流程路由
  PROCESS: '5-5',
  // 流程修改
  PROCESS_EDIT: '5-5-16',
  PROCESS_READONLY: '5-5-17',

  /** 文件管理 */
  // 文件
  FILE: '5-10',
  // 添加文件
  FILE_ADD: '5-10-36',
  // 删除文件
  FILE_DELETE: '5-10-37',
  // 文件查看
  FILE_PREVIEW: '5-10-38',


  /** 工单 */
  // 工单
  ORDER: '5-9',
  // 发起工单
  ORDER_CREATE: '5-9-33',
  // 工单审核
  ORDER_CHECK: '5-9-35',
  // 工单只读
  ORDER_CHECK_READONLY: '5-9-34',

  /** 系统管理 */
  SYSTEM_MANAGE: '5-11',
  // 验收
  SYSTEM_MANAGE_CHECK: '5-11-39',
  // 维修
  SYSTEM_MANAGE_FIX: '5-11-40',
  // 调整
  SYSTEM_MANAGE_ADJUST: '5-11-41',
  // 处置
  SYSTEM_MANAGE_SCRAP: '5-11-42',
  // 检定校准
  SYSTEM_MANAGE_VERITY: '5-11-43',
}

