import axios from 'axios'
import { UserModule } from '@/store/modules/user'
import router from '@/router'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 18000
})

// Request interceptors
http.interceptors.request.use(
  (config) => {
    // @ts-ignore
    if (['/file/download', 'instrument/export', 'isExport'].find((url) => config.url.indexOf(url) > -1)) {
      config.timeout = 30000
    }
    return { ...config, url: `${config.url}` }
  },
  (error) => {
    Promise.reject(error)
  }
)

export interface BaseResponse {
  success: boolean
  code: number
  message: string
  data: any
  httpCode: string
  config: any
}

// Response interceptors
http.interceptors.response.use(
  async (response: any) => {
    const res: BaseResponse = response.data
    // 流文件直接返回
    if (['/file/download', 'instrument/export', 'isExport'].find((url) => response?.config.url.indexOf(url) > -1)) {
      return response
    }
    if (!res.success) {
      if (res.code === 401) {
        if (router.currentRoute.path === '/login') {
          return Promise.reject(new Error(res.message || 'Error'))
        } else {
          UserModule.ResetUser()
          await router.push(`/login?redirect=${router.currentRoute.fullPath}`)
          return null
        }
      } else if (res.code === 304) {
        const [username] = res?.message?.split('/') || []
        await router.push(`/password?username=${username}`)
        return Promise.reject(new Error(res.message || 'Error'))
      } else {
        return Promise.reject(new Error(res.message || 'Error'))
      }
    } else {
      return response.data.data
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const encodeParams = (queryParams: any) => {
  if (queryParams) {
    const urlParam = new URLSearchParams()
    for (const key in queryParams) {
      // eslint-disable-next-line no-prototype-builtins
      if (queryParams.hasOwnProperty(key) && queryParams[key] !== '' && typeof queryParams[key] !== 'undefined') {
        urlParam.set(key, queryParams[key])
      }
    }
    return '?' + urlParam.toString()
  } else {
    return ''
  }
}

export default http
