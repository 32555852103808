













































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import Hamburger from "@/components/Hamburger/index.vue";
import { IUserDetail } from "@/api/user";
import PERMISSION from "@/constants/permission";

@Component({
  name: "Navbar",
  components: {
    Breadcrumb,
    Hamburger,
  },
})
export default class extends Vue {
  get currentUser(): IUserDetail {
    return UserModule.user;
  }

  get sidebar() {
    return AppModule.sidebar;
  }

  get PERMISSION() {
    return PERMISSION;
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(false);
  }

  private async logout() {
    await UserModule.LogOut();
    await this.$router.push(`/login?redirect=${this.$route.fullPath}`);
  }
}
