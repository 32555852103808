/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'depart': {
    width: 130,
    height: 130,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M860.587 704.427L752 679.253c34.56-18.56 58.24-56.746 58.24-100.266v-50.134c0-62.08-48-112.853-106.667-112.853-49.066 0-90.666 35.84-103.04 83.84-.213.64-.213 1.067-.426 1.707-.64 2.346-1.067 4.693-1.494 7.253-.213 1.493-.426 2.773-.64 4.053-.213 1.92-.64 3.627-.64 5.547-.213 3.413-.64 6.827-.64 10.24v50.133c0 4.694.427 9.387.854 14.08.213 1.707.64 3.2.853 4.907.64 2.773 1.067 5.547 1.707 8.533.426 1.92.853 3.84 1.493 5.974a110.354 110.354 0 003.84 10.88c9.813 24.106 27.307 44.16 49.493 56.106l-9.813 2.347-98.56 22.827c-32.427 7.253-55.893 37.973-55.893 73.6v89.386c0 27.734 21.333 50.134 47.36 50.134h331.946c26.027-.214 47.36-22.614 47.36-50.347v-89.387c-.426-35.413-23.893-66.346-56.746-73.386zm-465.92 148.906h-185.6l171.306-95.36c15.147-6.826 24.96-21.973 24.96-38.826V304.853c0-16.64-9.813-32-24.96-38.826l-171.306-95.36H576c11.733 0 21.333 9.6 21.333 21.333v117.333c0 17.707 14.294 32 32 32s32-14.293 32-32V192c0-47.147-38.186-85.333-85.333-85.333H192c-47.147 0-85.333 38.186-85.333 85.333v640c0 47.147 38.186 85.333 85.333 85.333h202.667c17.706 0 32-14.293 32-32s-14.294-32-32-32z" _fill="#979797"/>'
  }
})
