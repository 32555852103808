/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'file': {
    width: 130,
    height: 130,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M815.104 69.632q27.648 25.6 44.032 42.496t25.088 28.672 10.752 19.968 2.048 14.336v16.384H745.472q-10.24 0-17.92-7.68t-12.8-17.92-7.68-20.992-2.56-16.896V1.024h3.072q8.192 0 16.896 2.56t19.968 9.728 28.16 20.48 42.496 35.84zM640 129.024q0 20.48 6.144 42.496t19.456 40.96 33.792 31.232T747.52 256h149.504v577.536q0 29.696-11.776 53.248t-31.232 39.936-43.008 25.6-46.08 9.216H261.12q-19.456 0-42.496-11.264t-43.008-29.696-33.28-41.984-13.312-49.152V133.12q0-21.504 9.728-44.544t26.624-42.496 38.4-32.256 45.056-12.8H640v128zM704.512 768q26.624 0 45.056-18.944T768 703.488t-18.432-45.056T704.512 640h-384q-26.624 0-45.056 18.432t-18.432 45.056 18.432 45.568T320.512 768h384zM768 448.512q0-26.624-18.432-45.568T704.512 384h-384q-26.624 0-45.056 18.944t-18.432 45.568 18.432 45.056T320.512 512h384q26.624 0 45.056-18.432T768 448.512z" _fill="#979797"/>'
  }
})
