/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'enter': {
    width: 130,
    height: 130,
    viewBox: '0 0 1399 1024',
    data: '<path pid="0" d="M1189.543 858.077h-31.45V400.981c0-27.68-22.434-50.12-50.123-50.12H883.463V50.123C883.463 22.442 861.03 0 833.341 0H337.129c-27.674 0-50.119 22.442-50.119 50.122v807.955h-11.364c-27.68 0-50.122 22.433-50.122 50.11v65.689c0 27.677 22.442 50.119 50.122 50.119h913.897c27.683 0 50.116-22.442 50.116-50.12v-65.688c0-27.677-22.43-50.11-50.116-50.11zM739.786 674.98c0 27.69-22.446 50.123-50.123 50.123H480.82c-27.683 0-50.122-22.433-50.122-50.123v-15.035c0-27.68 22.44-50.116 50.122-50.116h208.844c27.677 0 50.123 22.436 50.123 50.116v15.035zm0-218.862c0 27.68-22.446 50.122-50.123 50.122H480.82c-27.683 0-50.122-22.442-50.122-50.122v-15.035c0-27.68 22.44-50.122 50.122-50.122h208.844c27.677 0 50.123 22.442 50.123 50.122v15.035zm0-235.58c0 27.684-22.446 50.123-50.123 50.123H480.82c-27.683 0-50.122-22.44-50.122-50.122v-15.03c0-27.688 22.44-50.124 50.122-50.124h208.844c27.677 0 50.123 22.439 50.123 50.125v15.029zm319.731 543.836h-133.66c-23.063 0-41.766-18.703-41.766-41.774 0-23.06 18.703-41.767 41.767-41.767h133.66c23.07 0 41.77 18.707 41.77 41.767.002 23.07-18.7 41.774-41.77 41.774zm0-168.753h-133.66c-23.063 0-41.766-18.7-41.766-41.76 0-23.08 18.703-41.777 41.767-41.777h133.66c23.07 0 41.77 18.7 41.77 41.776.002 23.061-18.7 41.761-41.77 41.761zm0 0" _fill="#979797"/>'
  }
})
