


















































import path from "path";
import { Component, Prop, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { isExternal } from "@/utils/validate";
import SidebarItemLink from "./SidebarItemLink.vue";
import { UserModule } from "@/store/modules/user";

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: "SidebarItem",
  components: {
    SidebarItemLink,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private item!: RouteConfig;
  @Prop({ default: false }) private isCollapse!: boolean;
  @Prop({ default: true }) private isFirstLevel!: boolean;
  @Prop({ default: "" }) private basePath!: string;

  get permission() {
    return UserModule.permission || [];
  }

  get user() {
    return UserModule.user;
  }

  get isShow() {
    // 权限过滤以及本不是菜单的路由
    const { item, permission, user }: any = this;
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id);
    // 企业管理员可以看到用户界面所有菜单
    if (isAdmin && !item.meta?.superAdminOnly && !item.meta?.hidden) return true;
    if (UserModule.isSuperAdmin && item.meta?.userOnly) return false;
    if (!UserModule.isSuperAdmin && item.meta?.superAdminOnly) return false;
    // 需要隐藏的直接隐藏
    if (item.meta?.hidden) return false;
    // 超管无需校验权限
    if (UserModule.isSuperAdmin) return true;
    // 如果有权限码但是不在权限列表中隐藏
    if (
      item.meta?.permissionCode &&
      !(permission as any).includes(item.meta?.permissionCode)
    )
      return false;
    // 如果有权限组但是没有一个在权限列表中隐藏
    if (
      item.meta?.permissionCodes?.length &&
      !item.meta?.permissionCodes?.find((c: any) =>
        (permission as any).includes(c)
      )
    )
      return false;
    return true;
  }

  get showingChildNumber() {
    if (this.item.children) {
      const showingChildren = this.item.children.filter((item) => {
        if (item.meta && item.meta.hidden) {
          return false;
        } else {
          return true;
        }
      });
      return showingChildren.length;
    }
    return 0;
  }

  get theOnlyOneChild() {
    if (this.showingChildNumber > 1) {
      return null;
    }
    if (this.item.children) {
      for (const child of this.item.children) {
        if (!child.meta || !child.meta.hidden) {
          return child;
        }
      }
    }
    // If there is no children, return itself with path removed,
    // because this.basePath already conatins item's path information
    return { ...this.item, path: "" };
  }

  private resolvePath(routePath: string) {
    if (isExternal(routePath)) {
      return routePath;
    }
    if (isExternal(this.basePath)) {
      return this.basePath;
    }
    return path.resolve(this.basePath, routePath);
  }
}
